import axios from '@axios'

export default {
  namespaced: true,
  state: {
    posts: [],
  },
  getters: {},
  mutations: {
    GET_POSTS(state, data) {
      state.posts = data
    },
  },
  actions: {
    async fetchPosts({ commit }) {
      const res = await axios.get('http://localhost:3000/posts')
      commit('GET_POSTS', res.data)
    },
    addPosts(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('http://localhost:3000/posts', data)
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
  },
}
