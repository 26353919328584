/* eslint-disable global-require */
/* eslint-disable no-unused-vars */
import {
  // eslint-disable-next-line no-unused-vars
  ref, watch, computed, reactive, watchEffect,
} from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'

export default function blog() {
  const toast = useToast()
  const addPostData = ref({
    title: null,
    content: null,
  })

  const getPosts = () => store.dispatch('blogsModule/fetchPosts')
  getPosts()

  const posts = computed(() => store.state.blogsModule.posts)

  const addPost = () => {
    store.dispatch('blogsModule/addPosts', { data: addPostData.value }).then(res => {
      if (res.status === 201) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Added Successfully',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
        router.push('/blog')
        // eslint-disable-next-line no-empty
      } else {}
    })
  }

  return {
    toast,
    posts,
    addPostData,
    addPost,
  }
}
